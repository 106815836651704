import type { LocalStorageFunctions, IntUserResponse, LocalECommerceFields } from './types';
import type { WebCheckerField } from 'pages/WebCheckerECommerce/types';

const ECOMMERCE_FIELDS: string = 'e-commerce-fields';
const USER: string = 'tigim-user';

const getUser = (fn: LocalStorageFunctions) => (): IntUserResponse | undefined =>
  fn.readObject(USER);

const getLocalStorageECommerceFields = (fn: LocalStorageFunctions) => (domain: string): WebCheckerField[] => {
  const user = getUser(fn)();
  if (!user) return [];
  const choice = fn.readObject(ECOMMERCE_FIELDS);
  if (!choice?.[user.user.email]) return [];
  const fields = choice[user.user.email];
  if (!fields[domain]) return [];
  return fields[domain];
};

const setLocalStorageECommerceFields = (fn: LocalStorageFunctions) => (domain: string, data: WebCheckerField[]) => {
  const user = getUser(fn)();
  if (!user) return;
  let choice = fn.readObject(ECOMMERCE_FIELDS);
  if (!choice) {
    choice = {};
  }
  if (!choice[user.user.email]) {
    choice[user.user.email] = {};
  }
  choice[user.user.email][domain] = data;
  fn.writeObject(ECOMMERCE_FIELDS, choice);
};

const delLocalStorageECommerceFields = (fn: LocalStorageFunctions) => (domain: string) => {
  const user = getUser(fn)();
  if (!user) return;
  const choice = fn.readObject(ECOMMERCE_FIELDS);
  if (!choice?.[user.user.email]) return;
  if (!choice[user.user.email][domain]) return;
  delete choice[user.user.email][domain];
  fn.writeObject(ECOMMERCE_FIELDS, choice);
};

const eCommerceFields = (fn: LocalStorageFunctions): LocalECommerceFields => ({
  getLocalStorageECommerceFields: getLocalStorageECommerceFields(fn),
  setLocalStorageECommerceFields: setLocalStorageECommerceFields(fn),
  delLocalStorageECommerceFields: delLocalStorageECommerceFields(fn)
});

export default eCommerceFields;
